//// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//@import 'bootstrap.scss';

@import '~boxicons';

@import 'prism-editor';


@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/ProximaNovaRegular.ttf") format('truetype') ;
}

body {
    background-color: #f8f9fa;
    font-family: "Proxima Nova", sans-serif;
}

.tox-notifications-container {display: none !important;}

.modal-backdrop {
    background-color: #00000080 !important;
}


.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tree-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tree-node {
    transition: $transition-delay;
    border-radius: $border-radius;
}

.slug {
	min-width: 60px;
	width: 60px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 0.8rem;
	color: $blue;

	&:hover {
		text-decoration: underline;
	}
}

a, a:visited, a:hover {
	text-decoration: none;
	color: inherit;
}

.list-element {
	cursor: pointer;
	transition: 0.3s;
	padding: 10px;
	border-radius: 5px;
	&:hover {
		background: $green-50;
	}

	&-active {
		background: $green-30;
	}
}


.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active до версии 2.1.8 */ {
	transform: translateX(10px);
	opacity: 0;
}

.action-button {
	padding: 3px;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	transition: 0.2s;

	i {
		margin: 0 auto;
	}

	&:hover {
		background: white;
	}
}

.last-modified {
	border: 1px #1d68a7 dashed;
	box-sizing: content-box;
}
