@import "~prismjs/themes/prism-tomorrow.css";

.code-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: white;
	color: #333;

	/* you must provide font-family font-size line-height. Example: */
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
	outline: none;
}
